import React, {useRef, useEffect, useState} from 'react'
import Layout from '../../components/layout'
import { useLanguages } from '../../hooks/useLanguages'
import loadable from '@loadable/component'
import HubspotForm from "react-hubspot-form"

const TabV2Contents = [
    {
        tabName: 'Onboarding Kits',
        heading: 'Make new hires feel at home with onboarding kits',
        description: 'Make an everlasting first impressions and create a sense of belonging with our onboarding kits and be the organization that value employees from the beginning.',
        image: '2024/07/Swags-onboarding-kits',
        tabID: 'kits',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Gift Boxes',
        heading: 'Unwrap joy this season with festive gift boxes',
        description: 'From decadent chocolates and delightful sweets to thoughtfully chosen utility items, our festive hampers, beautifully presented in customized boxes, cater to every taste and preference.',
        image: '2024/07/Swags-gift-boxes',
        tabID: 'giftBoxes',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Long Service Awards',
        heading: 'Deliver a delightful experience with Long Service Awards and Gifts',
        description: `From milestone trophies to curated catalogs, we offer a range of thoughtful and customizable gifts that reflect your employees' dedication and contributions.`,
        image: '2024/07/Swags-long-service-awards',
        tabID: 'longService',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Branded Swags',
        heading: 'Offer your employees a variety of Branded Swags',
        description: 'Give your employees the flexibility to redeem gift points for merchandise of their choice, across top global brands, with your company branding and have it delivered to their door in no time.',
        image: '2024/07/Swags-brand-swags',
        tabID: 'brandedSwags',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Experiences',
        heading: 'Gift your employees a once-in-a-lifetime experience',
        description: 'Give your employees a whole new redemption option to break the daily mundane & live the perfect work-life balance with relaxing hotel stays, outdoor adventure, budget holiday packages, and more.',
        image: '2024/07/Swags-experiences',
        tabID: 'experience',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Brand Store',
        heading: 'Create your personalized Merchandise Rewards boutique',
        description: 'Onboard your preferred vendors and curate a unique online storefront stocked with products and services specifically chosen for your employees.',
        image: '2024/07/Swags-brand-stores',
        tabID: 'brandStore',
        knowMoreLink:'#form'
    },
    {
        tabName: 'Gift Cards',
        heading: 'Celebrate every occasion in the perfect way with gift cards',
        description: 'Amplify the collective joy in your workplace by offering your employees gift cards ranging from a wide variety of local-to-global brands that suit every occasion.',
        image: '2024/07/Swags-gift-cards',
        tabID: 'giftCards',
        knowMoreLink:'#form'
    },
]


const flipperContents = [
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Onboarding Kits',
        heading: 'Make new hires feel at home with onboarding kits',
        paragraph: 'Make an everlasting first impressions and create a sense of belonging with our onboarding kits and be the organization that value employees from the beginning.',
        image: '2024/07/Swags-onboarding-kits',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Gift Boxes',
        heading: 'Unwrap joy this season with festive gift boxes',
        paragraph: 'From decadent chocolates and delightful sweets to thoughtfully chosen utility items, our festive hampers, beautifully presented in customized boxes, cater to every taste and preference.',
        image:'2024/07/Swags-gift-boxes',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Long Service Awards',
        heading: 'Deliver a delightful experience with Long Service Awards and Gifts',
        paragraph: `From milestone trophies to curated catalogs, we offer a range of thoughtful and customizable gifts that reflect your employees' dedication and contributions.`,
        image: '2024/07/Swags-long-service-awards',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Brand Swags',
        heading: 'Offer your employees a variety of Branded Swags',
        paragraph: 'Give your employees the flexibility to redeem gift points for merchandise of their choice, across top global brands, with your company branding and have it delivered to their door in no time.',
        image:'2024/07/Swags-brand-swags',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Experiences',
        heading: 'Gift your employees a once-in-a-lifetime experience',
        paragraph: 'Give your employees a whole new redemption option to break the daily mundane & live the perfect work-life balance with relaxing hotel stays, outdoor adventure, budget holiday packages, and more.',
        image:'2024/07/Swags-experiences',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Brand Store',
        heading: 'Create your personalized Merchandise Rewards boutique',
        paragraph: 'Onboard your preferred vendors and curate a unique online storefront stocked with products and services specifically chosen for your employees.',
        image:'2024/07/Swags-brand-stores',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    },
    {
        imageMobilePaddingBottom: 'pb-3',
        mobileImageQuality: 'w_210',
        tabName: 'Gift Cards',
        heading: 'Celebrate every occasion in the perfect way with gift cards',
        paragraph: 'Amplify the collective joy in your workplace by offering your employees gift cards ranging from a wide variety of local-to-global brands that suit every occasion.',
        image:'2024/07/Swags-gift-cards',
        knowMoreLink: '#form',
        tabHeightMobile: 'h-200px'
    }
  ]

const VantageSwags = ({ location }) => {
    const[currLanguage] = useLanguages()
    const [tabSection, setTabSection] = useState()

    const checkWidth = () => {
        if(window !== 'undefined'){
            if(window.innerWidth < 768){             
                const Flipper= loadable(() => import('../../components/Flipper'))
                setTabSection(<Flipper contents={flipperContents} />)
            }
            else{
                const TabV2= loadable(() => import('../../components/TabV2'))
                setTabSection(<TabV2 TabV2data={TabV2Contents} />)
            }
        }
    }

    useEffect(() => {
      checkWidth()
      const anchorLinks = document.querySelectorAll('a[href^="#"]')

      const handleAnchorClick = (e) => {
        const targetIDName = e.target.getAttribute('href').substring(1)
        const targetElement = document.getElementById(targetIDName)

        if(targetElement){
            console.log('targetelement working 003', targetElement.offsetTop, targetElement.offsetTop - 100);

            window.scrollTo({
                top: (targetElement.offsetTop) - 100,
                behavior: 'smooth'
              });
        }
      }
      
      anchorLinks.forEach(anchor => {
        anchor.addEventListener('click', handleAnchorClick)
      })

      return () => {
        anchorLinks.forEach(anchor => {
            anchor.removeEventListener('click', handleAnchorClick)
          })
      }
    
    }, [])
    

  return (
    <Layout location={location} pageLanguage={currLanguage}>
        <section
        id="watchVideoClass"
        class="w-11/12 2xl:max-w-7xl mx-auto mb-5 rounded-xl flex flex-col place-content-center relative bg-purple-80 overflow-x-hidden xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0 mb-10"
      >
        <div className="w-full grid xl:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 py-10 xl:py-20">   
            <picture>
                <source className='w-full h-auto md:h-full rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/07/Vantage_Swags-logo.webp`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="300" />
                <img className='w-full h-auto md:h-full rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_220/q_auto/gatsbycms/uploads/2024/07/Vantage_Swags-logo.webp`} alt="Vantage Perks" width="155" />
            </picture>
            <div className="my-2 flex justify-center items-center">
              <h2 className="pl-1 text-center lg:text-left heroTagStyle">
                <span className="font-bold">CORPORATE GIFTING </span>
              </h2>
            </div>
            <h1 class="lg:pr-3 xl:w-full homepage-heading text-center lg:text-left relative">
              Curate unforgettable <span className="text-orange">corporate gifts</span> that leave lasting impressions
            </h1>
            <p
              className="my-8 text-indigo-100 text-center lg:text-left w-full text-black"
              style={{ lineHeight: "2rem" }}
            >
              
              Celebrate every occasion that matters to your employees with our tailor-made range of personalized gifting solutions for your employees to enhance their overall experience.
            </p>
            
            <div className="">
              <a
                href="#form"
                className="vc-new-orange-btn-rounded-full text-purple-100 lato rounded-full mt-3 md:mt-0"
              >
                Talk to us
              </a>
            </div>
          </div>
          
          <div className="flex justify-center items-end h-full pt-4 xl:mt-0 lg:ml-10">
            <picture>
            <source
                className="z-10 relative mx-auto"
                
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_750/q_auto/gatsbycms/uploads/2024/07/Swags-hero-image.webp"
                media="(min-width: 1023px)"
                type="image/webp"
                alt="vantage-rewards-social-recognition"
                width="600"
              />
              <source
                className="z-10 relative mx-auto"
                
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Swags-hero-image.webp"
                media="(min-width: 640px)"
                type="image/webp"
                alt="vantage-rewards-social-recognition"
                width="550"
              />
              <img
                className="z-10 relative mx-auto"
                
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/07/Swags-hero-image.webp"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/07/Swags-hero-image.webp"
                alt="vantage-rewards-social-recognition"
                width="410"
              />
            </picture>
          </div>
        </div>
      </section> 
      
      <section className='py-10 xl:py-16 w-full px-5 md:px-6'>
        <div className='max-w-6xl xl:max-w-7xl mx-auto'>
            <h2 className='homepage-section-heading text-center max-w-5xl mx-auto'>Strengthen your workplace connections with <span className='text-orange'>thoughtful gifts</span></h2>
            <div className='md:grid grid-cols-2 xl:grid-cols-4 gap-7 place-content-center mt-12'>
                <div className='shadow rounded-xl px-5 py-8 mb-6 lg:my-0'>
                    <picture>
                        <source className='rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/07/Swags-ico-connect.webp`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="60" />
                        <img className='rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_220/q_auto/gatsbycms/uploads/2024/07/Swags-ico-connect.webp`} alt="Vantage Perks" width="60" />
                    </picture>
                    <p className='text-left text-gray-500 mt-4 mb-1'>
                        Connect with employees who have been instrumental in your organization'success
                    </p>
                </div>
                <div className='shadow rounded-xl px-5 py-8 my-6 lg:my-0'>
                    <picture>
                        <source className='rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/07/Swags-ico-re-connect.webp`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="60" />
                        <img className='rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_220/q_auto/gatsbycms/uploads/2024/07/Swags-ico-re-connect.webp`} alt="Vantage Perks" width="60" />
                    </picture>
                    <p className='text-left text-gray-500 mt-4 mb-1'>
                    Reconnect with your existing employees to instill a sense of belonging
                    </p>
                </div>
                <div className='shadow rounded-xl px-5 py-8 my-6 lg:my-0'>
                    <picture>
                        <source className='rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/07/Swags-ico-gratitude.webp`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="60" />
                        <img className='rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_220/q_auto/gatsbycms/uploads/2024/07/Swags-ico-gratitude.webp`} alt="Vantage Perks" width="60" />
                    </picture>
                    <p className='text-left text-gray-500 mt-4 mb-1'>
                    Show gratitude to those who have gone the extra mile to accomplish the impossible
                    </p>
                </div>
                <div className='shadow rounded-xl px-5 py-8 my-6 lg:my-0'>
                    <picture>
                        <source className='rounded-lg' srcset={`https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/07/Swags-delightful.webp`} media="(min-width: 640px)" type="image/webp"alt="Vantage Perks" width="60" />
                        <img className='rounded-lg' src={`https://res.cloudinary.com/vantagecircle/image/upload/w_220/q_auto/gatsbycms/uploads/2024/07/Swags-delightful.webp`} alt="Vantage Perks" width="60" />
                    </picture>
                    <p className='text-left text-gray-500 mt-4 mb-1'>
                    Deliver a delightful experience to those who have been an integral part of the journey
                    </p>
                </div>
            </div>
        </div>
      </section>
      

      <section className='py-10 xl:pt-16 xl:my-10 flex place-content-center px-3 md:px-6 2xl:max-w-7xl mx-auto lg:rounded-xl bg-purple-100'>
        <div className='max-w-7xl mx-auto xl:px-10'>
            <h2 className='homepage-section-heading mx-auto lg:max-w-4xl max-w-5xl text-center mb-10 px-2 lg:my-10'>
                <span className='text-orange'>
                    Our offerings&nbsp;
                </span>
                are designed to bring smiles and create memories
            </h2>
            {tabSection}
        </div>
      </section>

        <section className="w-full py-10 xl:py-16 px-5">
            <h2 className="homepage-heading text-center text-gray-250 mb-4 px-1 mx-auto">We are<span className="text-orange"> loved</span> by companies worldwide</h2>
            <ul className="grid orangebullets xl:grid-cols-2 mx-auto pt-5 xl:pt-10 max-w-5xl px-3 justify-center gap-5 xl:gap-9">
                <li className="xl:py-1 text-gray-200 my-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Cost-efficient gifting solutions</p>
                    <p className="pl-2 ml-0 py-2">Wide range of cost-effective gifting options to suit your company's budget.</p>
                </li>
                <li className="xl:py-1 text-gray-200 my-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Shipping to more than 100+ countries</p>
                    <p className="pl-2 ml-0 py-2">Enjoy a quick &amp; hassle-free shipping experience in more than 100+ countries.</p>
                </li>
                <li className="xl:py-1 text-gray-200 my-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Customizable gifting options</p>
                    <p className="pl-2 ml-0 py-2">Variety of gifting options that can be customized to fit your company's needs.</p>
                </li>
                <li className="xl:py-1 text-gray-200 my-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Customized packaging</p>
                    <p className="pl-2 ml-0 py-2">Unique custom packaging options for a better employee experience.</p>
                </li>
                <li className="xl:py-1 text-gray-200 mt-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Best-in-class customer support</p>
                    <p className="pl-2 ml-0 py-2">Reliable customer service that values customer's time rather than just meeting expectations.</p>
                </li>
                <li className="xl:py-1 text-gray-200 mt-1">
                    <p className="m-0 text-gray-500 text-xl lg:text-2xl pl-2">Doorstep delivery</p>
                    <p className="pl-2 ml-0 py-2">Enjoy convenient doorstep delivery, ensuring a seamless experience.</p>
                </li>
            </ul>
            {/* <div>
                <div className="grid" style={{gridTemplateColumns: '1fr 10fr'}}>
                    <div className="mx-auto"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height={30} width={30} /></div>
                    <div>
                        <p className="m-0 text-gray-500 text-xl xl:text-2xl pl-2">Cost-efficient gifting solutions</p>
                        <p className="pl-2 ml-0 py-2">Wide range of cost-effective gifting options to suit your company's budget.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="grid" style={{gridTemplateColumns: '1fr 10fr'}}>
                    <div className="mx-auto"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height={30} width={30} /></div>
                    <div>
                        <p className="m-0 text-gray-500 text-xl xl:text-2xl pl-2">Shipping to more than 100+ countries</p>
                        <p className="pl-2 ml-0 py-2">Enjoy a quick &amp; hassle-free shipping experience in more than 100+ countries.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="grid" style={{gridTemplateColumns: '1fr 10fr'}}>
                    <div className="mx-auto"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height={30} width={30} /></div>
                    <div>
                        <p className="m-0 text-gray-500 text-xl xl:text-2xl pl-2">Customizable gifting options</p>
                        <p className="pl-2 ml-0 py-2">Variety of gifting options that can be customized to fit your company's needs.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="grid" style={{gridTemplateColumns: '1fr 10fr'}}>
                    <div className="mx-auto"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height={30} width={30} /></div>
                    <div>
                        <p className="m-0 text-gray-500 text-xl xl:text-2xl pl-2">Customized packaging</p>
                        <p className="pl-2 ml-0 py-2">Unique custom packaging options for a better employee experience.</p>
                    </div>
                </div>
            </div>
            <div>
                <div className="grid" style={{gridTemplateColumns: '1fr 10fr'}}>
                    <div className="mx-auto"><img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2022/09/tick-1-1.webp" height={30} width={30} /></div>
                    <div>
                        <p className="m-0 text-gray-500 text-xl xl:text-2xl pl-2">Best-in-class customer support</p>
                        <p className="pl-2 ml-0 py-2">Reliable customer service that values customer's time rather than just meeting expectations.</p>
                    </div>
                </div>
            </div> */}
        </section>
        <section className="pb-10 xl:pb-16" id="clientsSection">
            <div className="w-full justify-center items-center flex flex-col pb-3">
                <h2 className="text-gray-250 lg:max-w-2xl xl:max-w-6xl mx-auto mb-0 text-center homepage-section-heading px-3"><span class="text-orange">700+</span> companies, from SMEs to Fortune 500, trust us!</h2></div>
            <div className="w-full justify-center items-center flex py-9">
                <picture>
                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1500/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2.webp" media="(min-width: 1200px)" width="1000"/>
                    <source loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2.webp" media="(min-width: 640px)" width="600"/>
                    <img loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_275/q_auto/gatsbycms/uploads/2023/12/vc-clients-strip-v2-mobile.webp" alt="A description of the image." width="275"/>
                </picture>
            </div>
            <div className="w-full flex justify-center"><a target="_blank" href="/corporates/" class="text-newOrange my-5 text-center vc-new-orange-border-btn-rounded-full">See all clients</a></div>
        </section>

        <section className='w-full py-10 xl:py-16 px-5 overflow-hidden' id="form">
            <div className='max-w-7xl bg-purple-100 rounded-xl mx-auto lg:flex p-5 justify-center relative overflow-hidden'>
                <div className='w-full lg:w-7/12 h-full grid py-5 xl:py-0 xl:mt-16'>
                    <h2 className='homepage-heading lg:w-9/12 lg:pl-5 text-center lg:text-left'><span className='text-orange'>Reach out to us </span>today to get:</h2>
                    <ul className="list-inside orangebullets text-gray-250 lato text-lg pl-1 md:pr-5 xl:pr-0 xl:pl-7 mt-4 lg:mt-10 text-left w-full md:w-8/12 lg:w-10/12">
                        <li className="py-1 text-gray-200 my-3">
                            <p className="m-0 text-gray-500" >
                            High-quality merchandise
                            </p>
                        </li>
                        <li className="py-1 text-gray-200 my-3">
                            <p className="m-0 text-gray-500">
                            Extensive gifting options
                            </p>
                        </li>
                        <li className="py-1 text-gray-200 my-3">
                            <p className="m-0 text-gray-500">
                            Zero warehousing burden
                            </p>
                        </li>
                        <li className="py-1 text-gray-200 my-3">
                            <p className="m-0 text-gray-500">
                            Doorstep Delivery
                            </p>
                        </li>
                    </ul>
                </div>
                <div className='w-full lg:w-5/12 mt-5 lg:mt-0 lg:p-5 flex items-center justify-center'>
                    <div className='bg-white z-10 flex place-content-center p-7 pt-10 rounded-xl rnrForm-WhiteFields' style={{ boxShadow: '1px 1px 25px rgba(0,0,0,0.1)' }}>
                        <div class="w-full flex justify-center items-center lato rewards team">
                            <div class="flex justify-center items-center">
                                <HubspotForm
                                portalId="6153052"
                                formId="109aff53-a8c6-42e6-a5c8-123d9bf4b970"
                                onSubmit={() => console.log("Submit!")}
                                onReady={form => console.log("Form ready!")}
                                loading={<div>Loading...</div>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='h-96 w-96 bg-indigo-100 rounded-full absolute -bottom-44 left-1/4 z-0 hidden lg:block'></div>
                <div className='h-80 w-80 bg-indigo-100 rounded-full absolute -top-44 left-1/2 z-0 hidden lg:block'></div>
            </div>
        </section>
    </Layout>
  )
}

export default VantageSwags